import React, {useContext, useEffect, useState} from 'react';
import Header from "../../components/Header";
import styled from "styled-components";
import {Button as MuiButton, CircularProgress, Grid, makeStyles, Theme, withStyles} from "@material-ui/core";
import Logo from "../../../../assets/images/logo-without-text.png";
import {useHistory, useParams} from 'react-router-dom';
import Product from "./components/Product";
import DiscountCodeSection from "./components/DiscountCodeSection";
import Input from "./components/Input";
import {AuthContext} from "../../../../shared/context/AuthContext";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {useFormik} from "formik";
import {validateSchema} from "./validateSchema";
import {StripeCardElementChangeEvent} from "@stripe/stripe-js";
import OrderSummary from "./components/OrderSummary";
import {IStripeProduct} from "../../../../shared/interfaces/StripeProduct";
import {useLazyQuery, useMutation} from "@apollo/client";
import {GET_PRODUCT} from "../../../../graphql/product/getProduct.query";
import {getApolloError} from "../../../../shared/utils/apolloError";
import {get} from 'lodash';
import {IDiscountCode} from "../../../../shared/interfaces/Discount";
import CREATE_STRIPE_SUBSCRIPTION from "../../../../graphql/checkout/createStripeSubscription.mutation";
import {ROOT_ROUTES, SUBSCRIPTION_ROUTES} from "../../../../shared/constants/routes";

const useStyles = makeStyles((theme: Theme) => ({
  cardInput: {
    paddingLeft: 6,
    paddingTop: 8,
    paddingBottom: 8,
    borderStyle: 'solid',
    borderRadius: 6,
    borderColor: theme.palette.grey["400"],
    border: 1,
    marginTop: 16
  }
}))

const Container = styled.div`
  max-width: 30%;
  margin: 0 auto;
`;

const MessageError = styled.p`
  color: #F44336;
  font-size: 16px;
`;

const Image = styled.img`
  width: 75px;
`;

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background-color: #d3d3d3;
`;

const SectionTitle = styled.p`
  font-weight: bold;
  margin: 0 0 4px 24px;
`

const cardOptions = {
  hidePostalCode: true,
  style: {
    base: {
      color: "#32325d",
      fontFamily: 'Roboto, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#32325d"
      }
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  }
};

const Button = withStyles((theme: Theme) => ({
  root: {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    fontWeight: 'bold',
    color: "white",
    width: '70%',
    '&:disabled': {
      backgroundColor: theme.palette.grey["300"],
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}))(MuiButton);

interface IForm {
  cc_complete: boolean;
  firstname: string;
  lastname: string;
  billingAddress: string;
  aptSuite: string;
  zipCode: string;
  city: string;
  state: string;
  phoneNumber: string;
  email: string;
}

const Checkout = () => {
  const history = useHistory();
  const params = useParams();
  const classes = useStyles();
  const elements = useElements();
  const stripe = useStripe();
  const {state: authState} = useContext(AuthContext);
  const [getProduct, {data: prodData, loading: productLoading, error: prodError}] = useLazyQuery(GET_PRODUCT);
  const [createSubscription, {
    data: subData,
    loading: subLoading,
    error: subError
  }] = useMutation(CREATE_STRIPE_SUBSCRIPTION);
  const [stringError, setStringError] = useState<string | null>(null);
  const [user, setUser] = useState<any>(null);
  const [product, setProduct] = useState<IStripeProduct | null>(null);
  const [discountCode, setDiscountCode] = useState<IDiscountCode | null>(null);
  console.log(prodError)
  const error = prodError || subError;
  const loading = productLoading || subLoading;

  const onSubmit = async (values: IForm) => {
    if (elements && stripe) {
      const card = elements.getElement(CardElement)
      if (card) {
        const result = await stripe.createPaymentMethod({
          type: 'card',
          card: card,
          billing_details: {
            name: `${values.firstname} ${values.lastname}`,
            address: {
              city: values.city,
              country: 'US',
              postal_code: values.zipCode,
              state: values.state,
              line1: values.billingAddress,
              line2: ''
            }
          }
        });
        if (!result.error) {
          console.log(result)
          createSubscription({
            variables: {
              input: {
                paymentMethodId: result.paymentMethod.id,
                priceId: product?.price.id,
                discountCode: discountCode ? discountCode.id : null
              }
            }
          })
        } else {
          setStringError('Error: Credit card verification');
        }
      }

    }
  }

  const formik = useFormik({
    initialValues: {
      cc_complete: false,
      aptSuite: '',
      billingAddress: '',
      city: '',
      firstname: '',
      lastname: '',
      zipCode: '',
      state: '',
      phoneNumber: '',
      email: user ? user.email : ''
    },
    onSubmit,
    // validateOnMount: true,
    validateOnChange: true,
    validationSchema: validateSchema
  })

  const _handleCardChange = (event: StripeCardElementChangeEvent) => {
    formik.setFieldValue('cc_complete', event.complete);
  }

  useEffect(() => {
    if (prodData) {
      setProduct(get(prodData, 'getStripeProduct', null))
    }
  }, [prodData]);

  useEffect(() => {
    if (subData) {
      const resp = get(subData, 'createSubscription', null);
      if (resp) {
        history.push(`/${ROOT_ROUTES.SUBSCRIPTION}/${SUBSCRIPTION_ROUTES.SUCCESS}`);
      }
    }
  }, [subData]);

  useEffect(() => {
    if (error) {
      console.log(error)
      setStringError(getApolloError(error));
    }
  }, [error]);

  useEffect(() => {
    // @ts-ignore
    if (params && params.id) {
      let productId;
      // @ts-ignore
      switch (params.id) {
        case "1":
          productId = process.env.REACT_APP_STRIPE_MONTHLY_PRICE
          break;
        case "2":
          productId = process.env.REACT_APP_STRIPE_YEARLY_PRICE
          break;
        default:
          productId = process.env.REACT_APP_STRIPE_MONTHLY_PRICE
          break;
      }
      getProduct({
        variables: {
          input: {stripeProductId: productId}
        }
      });

    }
  }, [params]);

  useEffect(() => {
    if (authState && authState.user) {
      setUser(authState.user);
      formik.setFieldValue('email', authState.user.email);
    }
  }, [authState]);

  return (
    <div>
      <Header/>
      <Container>
        <form onSubmit={formik.handleSubmit}>
          <Grid container style={{marginTop: 16}} alignItems={'center'} alignContent={'center'} spacing={2}>
            <Grid item xs={12} style={{textAlign: 'center'}}>
              <Image src={Logo}/>
            </Grid>
            {
              loading &&
              <Grid item xs={12} style={{textAlign: 'center'}}>
                <CircularProgress color={"primary"}/>
              </Grid>
            }
            <Grid item xs={12}>
              {
                product ?
                  <Product product={product}/>
                  :
                  <>
                    {
                      !productLoading &&
                      <p>Error loading the product</p>

                    }
                  </>
              }
            </Grid>
            <Grid item xs={12}>
              <Divider/>
            </Grid>
            <Grid item xs={12}>
              <DiscountCodeSection onDiscountCode={setDiscountCode} product={product}/>
            </Grid>
            <Grid item xs={12} style={{marginTop: 24}}>
              <SectionTitle>ADD PAYMENT CARD</SectionTitle>
              <CardElement
                options={cardOptions}
                className={classes.cardInput}
                onChange={_handleCardChange}/>
            </Grid>
            <Grid item xs={12}>
              <Input
                disabled={loading}
                name={"firstname"}
                onChange={formik.handleChange}
                value={formik.values.firstname}
                placeholder={"First Name"}/>
            </Grid>
            <Grid item xs={12}>
              <Input
                disabled={loading}
                name={"lastname"}
                onChange={formik.handleChange}
                value={formik.values.lastname}
                placeholder={"Last Name"}/>
            </Grid>
            <Grid item xs={12}>
              <Input
                disabled={loading}
                name={"billingAddress"}
                onChange={formik.handleChange}
                value={formik.values.billingAddress}
                placeholder={"Billing Address"}/>
            </Grid>
            <Grid item container xs={12} justify={'space-between'}>
              <Grid item xs={12} md={5}>
                <Input
                  disabled={loading}
                  name={"aptSuite"}
                  onChange={formik.handleChange}
                  value={formik.values.aptSuite}
                  placeholder={"Apt/Suite"}/>
              </Grid>
              <Grid item xs={12} md={5}>
                <Input
                  disabled={loading}
                  name={"zipCode"}
                  onChange={formik.handleChange}
                  value={formik.values.zipCode}
                  placeholder={"Zip code"}/>
              </Grid>
            </Grid>
            <Grid container item xs={12} justify={'space-between'}>
              <Grid item xs={12} md={5}>
                <Input
                  disabled={loading}
                  name={"city"}
                  onChange={formik.handleChange}
                  value={formik.values.city}
                  placeholder={"City"}/>
              </Grid>
              <Grid item xs={12} md={5}>
                <Input
                  disabled={loading}
                  name={"state"}
                  onChange={formik.handleChange}
                  value={formik.values.state}
                  placeholder={"State"}/>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Input
                disabled={loading}
                name={"phoneNumber"}
                onChange={formik.handleChange}
                value={formik.values.phoneNumber}
                placeholder={"Phone number"}/>
            </Grid>
            <Grid item xs={12} style={{marginTop: 24}}>
              <SectionTitle>SUBSCRIPTION/CONTACT EMAIL</SectionTitle>
              <Input
                disabled={loading}
                name={"email"}
                onChange={formik.handleChange}
                value={formik.values.email}
                placeholder={"Email"}/>
            </Grid>
            {
              product &&
              <OrderSummary product={product} discountCode={discountCode}/>
            }
            {
              stringError &&
              <MessageError>{stringError}</MessageError>
            }


            <Grid item xs={12} style={{textAlign: 'center'}}>
              <Button disabled={!formik.isValid || loading} type={'submit'}>PAY NOW</Button>
            </Grid>
          </Grid>

        </form>
      </Container>
    </div>
  )
};

export default Checkout;