import {gql} from '@apollo/client';

const selection = `
  interval_count
  interval`;

const StripePriceRecurringFragment = {
  fragment: gql`
      fragment StripePriceRecurring on StripePriceRecurring{
          ${selection}
      }
  `
}
export default StripePriceRecurringFragment;