export const ROOT_ROUTES = {
  AUTH: 'auth',
  SUBSCRIPTION: 'subscription',
  VERIFY: 'verify',
  CHANGE: 'change-password'
}

export const AUTH_ROUTES = {
  BASE: 'auth',
  LOGIN: 'login',
  REGISTER: 'register'
}

export const SUBSCRIPTION_ROUTES = {
  BASE: 'subscription',
  SUBSCRIBE: 'subscribe',
  CHECKOUT: 'checkout',
  SUCCESS: 'success'
}