import React from 'react';
import {Switch, useRouteMatch} from 'react-router-dom';
import PublicRoute from "../../shared/components/PublicRoute";
import Login from "./pages/Login";
import Register from "./pages/Register";
import {AUTH_ROUTES} from "../../shared/constants/routes";

const AuthRouter = () => {

  let {path} = useRouteMatch();

  return (
    <Switch>
      <PublicRoute exact path={`${path}/${AUTH_ROUTES.LOGIN}`}>
        <Login/>
      </PublicRoute>
      <PublicRoute exact path={`${path}/${AUTH_ROUTES.REGISTER}`}>
        <Register/>
      </PublicRoute>
    </Switch>
  )
}

export default AuthRouter;