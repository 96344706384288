import styled from "styled-components";


const Input = styled.input`
  border: 2px solid #d3d3d3;
  padding: 8px 0;
  text-indent: 16px;
  width: 100%;

  ::placeholder {
    color: #000000;
  }

  :focus {
    outline: none;
    border: 2px solid #d3d3d3;
  }
`

export default Input;