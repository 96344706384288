import React from 'react';
import {Route} from 'react-router-dom';

const PublicRoute = (props: any) => {

  const {component, ...rest} = props;


  return (
    <Route
      component={component}
      {...rest}
    />
  )
};

export default PublicRoute;
