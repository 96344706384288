import React, {useReducer} from "react";
import AuthContext from "./AuthContext";
import {AUTH_ACTIONS} from "./Constants";

const AuthProvider = (props: any) => {
  const [state, dispatch] = useReducer((prevState: any, action: any) => {
    switch (action.type) {
      case AUTH_ACTIONS.SET_TOKEN:
        localStorage.setItem('token', action.token);
        return {
          ...prevState,
          token: action.token,
          isLoading: false,
        };
      case AUTH_ACTIONS.LOGIN:
        localStorage.setItem('token', action.token);
        return {
          ...prevState,
          token: action.token,
          user: action.user,
          isLoading: false,
        };
      case AUTH_ACTIONS.SET_USER:
        return {
          ...prevState,
          user: action.user
        };
      case AUTH_ACTIONS.LOGOUT:
        localStorage.removeItem('token');
        return {
          ...prevState,
          token: null,
          isLoading: false,
          user: null
        };
      case AUTH_ACTIONS.SET_LOADING:
        return {
          ...prevState,
          isLoading: action.isLoading
        };
      default:
        throw new Error();
    }
  }, {
    user: null,
    emailForStripeCheckout: null,
    isLoading: true,
    token: null,
  });


  return (
    <AuthContext.Provider value={{state, dispatch}}>
      {props.children}
    </AuthContext.Provider>
  )
};

export default AuthProvider;
