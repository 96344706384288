import React from 'react';
import Header from "../../components/Header";
import Logo from "../../../../assets/images/logo-without-text.png";
import {Grid} from "@material-ui/core";
import styled from "styled-components";
import AndroidLogo from '../../../../assets/images/android-logo.png'
import IosLogo from '../../../../assets/images/ios-logo.png'
import {Link} from "react-router-dom";

const AndroidImage = styled.img`
  max-width: 200px;

`

const IosImage = styled.img`
  max-width: 200px;
  height: 105px;
`

const Image = styled.img`
  width: 75px;
`;

const Title = styled.p`
  font-weight: bold;
  font-size: 34px;
  text-align: center;
  max-width: 50%;
  margin: 0 auto;
`;

const Subtitle = styled.p`
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  max-width: 50%;
  margin: 0 auto;
`;

const TakeText = styled.p`
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  max-width: 50%;
  margin: 0 auto;
  text-decoration: underline;
  text-decoration-color: #f9cf2d;
  text-decoration-thickness: 3px;

`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-width: 20%;
  margin: 0 auto;
  align-items: center;
`;


const Success = () => {
  return (
    <div>
      <Header/>
      <Grid container style={{marginTop: 16}} alignItems={'center'} alignContent={'center'} spacing={2}>
        <Grid item xs={12} style={{textAlign: 'center'}}>
          <Image src={Logo}/>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'center'}}>
          <Title>THANK YOU</Title>
          <Subtitle>You are all signed up!</Subtitle>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'center'}}>
          <TakeText>TAKE ME TO THE APP</TakeText>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'center'}}>
          <Row>
            <a href={"https://play.google.com/store/apps/details?id=com.prixm.prixm"} target={"_blank"}>
              <AndroidImage src={AndroidLogo}/>
            </a>
            <a href={"https://apps.apple.com/us/app/prixm/id1375009803"} target={"_blank"}>
              <IosImage src={IosLogo}/>
            </a>
          </Row>
        </Grid>
      </Grid>
    </div>
  )
}

export default Success;