import React from 'react';
import {Switch, useRouteMatch} from "react-router-dom";
import PrivateRoute from "../../shared/components/PrivateRoute";
import Subscribe from "./pages/Subscribe";
import {SUBSCRIPTION_ROUTES} from "../../shared/constants/routes";
import Checkout from "./pages/Checkout";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import Success from "./pages/Success";

const STRIPE_PROMISE = loadStripe(String(process.env.REACT_APP_STRIPE));


const SubscriptionRouter = () => {

  let {path} = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute exact path={`${path}/${SUBSCRIPTION_ROUTES.SUBSCRIBE}`}>
        <Subscribe/>
      </PrivateRoute>
      <PrivateRoute exact path={`${path}/${SUBSCRIPTION_ROUTES.CHECKOUT}/:id`}>
        <Elements stripe={STRIPE_PROMISE}>
          <Checkout/>
        </Elements>
      </PrivateRoute>
      <PrivateRoute exact path={`${path}/${SUBSCRIPTION_ROUTES.SUCCESS}`}>
        <Success/>
      </PrivateRoute>
    </Switch>
  )
};


export default SubscriptionRouter;