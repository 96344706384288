import React from 'react';
import styled from "styled-components";
import {Checkbox, useTheme, withStyles} from "@material-ui/core";

interface IProps {
  id: number;
  title: string;
  subtitle: string;
  selected: boolean;
  onSelectItem: () => void;
}

interface IContainerProps {
  color?: string;
  border?: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 60%;
  margin: 0 auto;
  background-color: ${(props: IContainerProps) => props.color || "#FFFFFF"};
  padding: 8px;
  border: ${(props: IContainerProps) => props.border ? '3px solid #E0E0E0' : "none"};

  :hover {
    cursor: pointer;
  }
`;

const TextContainer = styled.div`
  margin-top: 6px;
`

const Title = styled.p`
  font-weight: bolder;
  font-size: 20px;
  margin: 0
`;

const Subtitle = styled.p`
  margin: 0;
`;


const WhiteBackgroundCheckbox = withStyles(theme => ({
  root: {
    color: (props: any) => props.color === 'default' ? 'white' : props.color,
    "& .MuiIconButton-label": {
      position: "relative",
      zIndex: 0
    },
    "&:not($checked) .MuiIconButton-label:after": {
      content: '""',
      left: 4,
      top: 4,
      height: 15,
      width: 15,
      position: "absolute",
      backgroundColor: "white",
      zIndex: -1
    }
  },
  checked: {}
}))(Checkbox);

const Product = (props: IProps) => {

  const {id, title, subtitle, onSelectItem, selected} = props;
  const theme = useTheme();

  const _getColor = () => {
    switch (id) {
      case 1:
        return "#BDBDBD";
      case 2:
        return theme.palette.primary.main;
      case 3:
        return "#FFFFFF";
      default:
        return "FFFFFF"
    }
  }

  return (
    <Container color={_getColor()} border={id === 3} onClick={onSelectItem}>
      <div>
        <WhiteBackgroundCheckbox
          checked={selected} onChange={onSelectItem} color={id === 3 ? 'primary' : 'default'}/>
      </div>
      <TextContainer>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </TextContainer>
    </Container>
  )
};

export default Product;