import {ApolloClient, createHttpLink, InMemoryCache} from '@apollo/client';
import {setContext} from '@apollo/link-context';
// @ts-ignore
import {onError} from "@apollo/link-error";
//LIBS & CONSTANTS

const REACT_APP_API_URL = process.env[`REACT_APP_API_URL`];
const REACT_APP_GRAPH_ENDPOINT = process.env[`REACT_APP_GRAPH_ENDPOINT`];


const httpLink = createHttpLink({uri: `${REACT_APP_API_URL}/${REACT_APP_GRAPH_ENDPOINT}`});

const authLink = setContext(async (_, {headers}) => {
  const token = await localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      apiVersion: "1.1",
      Authorization: token ? `${token}` : '',
    }
  }
});
/**
 * response: response from the server
 * graphQLErrors: GraphQLErrors
 * networkError: network Error
 */
const error = onError(({graphQLErrors, networkError, response}) => {
  if (graphQLErrors)

    //TODO: HTTP STATUS
    if (graphQLErrors[0] !== null) {
      graphQLErrors.map(({message, locations, path}) => {
        return message;
      });
    }

  // if (networkError) console.log(`[Network error]: ${networkError}`);
});

const cache = new InMemoryCache({});


const apolloClient = new ApolloClient({
  link: error.concat(authLink.concat(httpLink)),
  cache: cache,
  defaultOptions: {
    mutate: {errorPolicy: 'all'}
  }
});

export default apolloClient;