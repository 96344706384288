import React, {useState} from 'react';
import styled from "styled-components";
import Input from "../../../../../../shared/components/Input";
import Button from "../../../../../../shared/components/Button";
import {Checkbox, CircularProgress, Grid, useTheme} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {AUTH_ROUTES} from "../../../../../../shared/constants/routes";
import {AuthContainer} from "../../../../components/AuthContainer";
import {Underline} from "../../../../components/Form/Underline";
import {ItemForm} from "../../../../components/Form/ItemForm";
import RestApi from "../../../../../../config/rest-api.config";
import {useFormik} from "formik";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  email: yup.string().email().required("Required field"),
  firstName: yup.string().required("Required field"),
  lastName: yup.string().required("Required field"),
  password: yup.string().required("Required field"),
  check: yup.boolean().oneOf([true], 'Field must be checked'),
  passwordConfirmation: yup.string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
})

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  margin: 0 auto
`;

const MessageError = styled.p`
  font-size: 18px;
  color: #F44336;
  margin:0 auto;
`

const Title = styled.p`
  font-weight: bold;
  font-size: 24px;
  margin: 0;
`;

const Subtitle = styled.p`
  font-size: 14px;
  margin: 0;
`

const TermsCond = styled.p`
  font-size: 14px;
  text-align: center;
  margin: 0;
`

const Footer = styled.p`
  font-weight: bold;
  font-size: 14px;
  margin: 0;
`;

const GRID_STYLES = {width: "100%", margin: "0 auto"};


interface IForm {
  password: string;
  passwordConfirmation: string;
  email: string;
  firstName: string;
  lastName: string;
  check: boolean;
}

const BottomForm = () => {
    const theme = useTheme();
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const _goToLogin = () => {
      history.push(`/${AUTH_ROUTES.BASE}/${AUTH_ROUTES.LOGIN}`)
    }

    const onSubmit = (values: IForm) => {
      setError(null);
      setLoading(true)
      RestApi.post('signup', values)
        .then((res) => {
          setSuccess(true);

        })
        .catch(err => {
          if (err.response) {
            setError(err.response.data.message || 'Wrong credentials')
          } else {
            setError('Wrong credentials')
          }
        })
        .finally(() => setLoading(false));
    }

    const formik = useFormik({
      initialValues: {
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        passwordConfirmation: "",
        check: false,
      },
      onSubmit,
      validationSchema
    })

    return (
      <AuthContainer style={{backgroundColor: theme.palette.primary.main}}>
        {
          loading &&
          <CircularProgress color={"secondary"}/>
        }
        {
          success ?
            <SuccessContainer>
              <Title>Thank you for registering! <br/>
                Please verify your email before proceeding.
              </Title>
              <Button onClick={() => _goToLogin()}>GO TO LOGIN</Button>
            </SuccessContainer>
            :
            <>
              <Title>Sign up for PRIXM</Title>
              <Subtitle>Let's get you creating, learning & connecting!</Subtitle>

              <form onSubmit={formik.handleSubmit} style={{marginTop: 16}}>
                <Grid container spacing={2} justify="center" alignItems="stretch" direction={'column'}>
                  <Grid item xs={12} md={4} style={GRID_STYLES}>
                    <Input
                      style={{width: '97%'}}
                      name={'firstName'}
                      onChange={formik.handleChange}
                      value={formik.values.firstName}
                      placeholder={"FIRST NAME"}/>
                  </Grid>
                  <Grid item xs={12} md={4} style={GRID_STYLES}>
                    <Input
                      style={{width: '97%'}}
                      name={'lastName'}
                      onChange={formik.handleChange}
                      value={formik.values.lastName}
                      placeholder={"LAST NAME"}/>
                  </Grid>
                  <Grid item xs={12} md={4} style={GRID_STYLES}>
                    <Input
                      style={{width: '97%'}}
                      name={'email'}
                      type={'email'}
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      placeholder={"EMAIL"}/>
                  </Grid>
                  <Grid item xs={12} md={4} style={GRID_STYLES}>
                    <Input
                      style={{width: '97%'}}
                      name={'password'}
                      type={'password'}
                      onChange={formik.handleChange}
                      value={formik.values.password}
                      placeholder={"PASSWORD"}/>
                  </Grid>
                  <Grid item xs={12} md={4} style={GRID_STYLES}>
                    <Input
                      style={{width: '97%'}}
                      name={'passwordConfirmation'}
                      type={'password'}
                      onChange={formik.handleChange}
                      value={formik.values.passwordConfirmation}
                      placeholder={"CONFIRM PASSWORD"}/>
                  </Grid>
                  <Grid item xs={12} md={4} style={GRID_STYLES}>
                    <TermsCond> <span><Checkbox name={'check'}
                                                onChange={formik.handleChange}
                                                value={formik.values.check}
                                                color="default"/></span> I Agree To All <Underline>Terms And
                      Conditions</Underline></TermsCond>
                  </Grid>

                  {
                    error &&
                    <Grid>
                      <MessageError>{error}</MessageError>
                    </Grid>
                  }

                  <Grid item xs={12} md={4} style={GRID_STYLES}>
                    <Button
                      type={"submit"} disabled={!formik.isValid || loading}>SIGN UP</Button>
                  </Grid>
                </Grid>

                <ItemForm>
                  <Footer>Already have an account? <Underline onClick={_goToLogin}>LOGIN</Underline></Footer>
                </ItemForm>
              </form>
            </>
        }
      </AuthContainer>
    )
  }
;

export default BottomForm;