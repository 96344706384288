import styled from "styled-components";
import Check from '../../../../assets/images/checkmark.svg';

interface IProps {
  text: string;
}

const Image = styled.img`
  width: 26px;
  height: 26px;
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Text = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
`

const Item = (props: IProps) => {
  const {text} = props;
  return (
    <Container>
      <Image src={Check}/>
      <Text>{text}</Text>
    </Container>
  )
};

export default Item;