import { gql } from "@apollo/client";
import DiscountCodeFragment from "../fragments/DiscountCode.fragment";


export const DISCOUNT_CODE_IS_ELIGIBLE = gql`
    query DiscountCodeIsEligible($input: DiscountCodeIsEligibleInput) {
        DiscountCodeIsEligible(input: $input) {
            ...DiscountCode
        }
    }
    ${DiscountCodeFragment.fragment}
`;

export default DISCOUNT_CODE_IS_ELIGIBLE;
