import styled from "styled-components";


const Input = styled.input`
  background-color: #FDF1C0;
  border: none;
  padding: 8px 0 8px 16px;

  ::placeholder {
    color: #000000;
  }
  
  :focus{
    outline: none;  
    border: none;  
  }
`

export default Input;