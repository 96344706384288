import React, {useContext, useEffect, useState} from 'react';
import Header from "../../components/Header";
import Logo from '../../../../assets/images/logo-without-text.png';
import {Button as MuiButton, Grid, Theme, withStyles} from "@material-ui/core";
import styled from "styled-components";
import Checklist from "../../components/Checklist";
import Product from "../../components/Product";
import {AUTH_ROUTES, ROOT_ROUTES, SUBSCRIPTION_ROUTES} from "../../../../shared/constants/routes";
import {useHistory} from "react-router-dom";
import {AuthContext} from "../../../../shared/context/AuthContext";
import {IUser} from "../../../../shared/interfaces/User";
import moment from 'moment';
import {AUTH_ACTIONS} from "../../../../shared/context/AuthContext/Constants";

const Image = styled.img`
  width: 75px;
`;

const Container = styled.div`
  max-width: 40%;
  margin: 0 auto;
`;

const Title = styled.p`
  font-weight: bold;
  font-size: 34px;
  text-align: center;
  max-width: 50%;
  margin: 0 auto;
`;

const Text = styled.p`
  text-align: center;
  font-size: 16px;
  margin: 0;
`;

const SubscriptionTerms = styled.p`
  font-size: 12px;
  text-align: center;

`;

const Button = withStyles((theme: Theme) => ({
  root: {
    borderRadius: 0,
    backgroundColor: "#4eafd1",
    fontWeight: 'bold',
    color: "white",
    width: '65%',
    '&:hover': {
      backgroundColor: "#4eafd1",
    },
    '&:disabled': {
      backgroundColor: "#039BE5",
    },
  },
}))(MuiButton);

const Subscribe = () => {
  const history = useHistory();
  const {state: authState, dispatch} = useContext(AuthContext);

  const [itemSelected, setItemSelected] = useState<number | null>(null);
  const [user, setUser] = useState<IUser | null>(null);
  const [newUser, setNewUser] = useState<boolean>(false);

  const _goToCheckout = () => {
    if (itemSelected !== 3) {
      history.push(`/${ROOT_ROUTES.SUBSCRIPTION}/${SUBSCRIPTION_ROUTES.CHECKOUT}/${itemSelected}`);
      return;
    } else {
      history.push(`/${ROOT_ROUTES.SUBSCRIPTION}/${SUBSCRIPTION_ROUTES.SUCCESS}`);
    }
  }
  useEffect(() => {
    if (user && user.createdAt) {
      const diff = moment().diff(user.createdAt, 'days');
      setNewUser(diff <= 1);
    }
  }, [user])

  useEffect(() => {
    if (authState && authState.user) {
      setUser(authState.user);
    }
  }, [authState]);

  return (
    <div>
      <Header/>
      <Container>
        <Grid container style={{marginTop: 16}} alignItems={'center'} alignContent={'center'} spacing={2}>
          <Grid item xs={12} justify={'center'} style={{textAlign: 'center'}}>
            <Image src={Logo}/>
          </Grid>
          <Grid item xs={12} justify={'center'}>
            <Title>{!newUser ? `Hey ${user?.firstName}, ` : ""}TRY PRIXM PLUS FOR FREE!</Title>
          </Grid>
          <Grid item xs={12} justify={'center'}>
            <Text>The PRIXM Solution</Text>
          </Grid>
          <Grid item xs={12} justify={'center'}>
            <Text>Your stories, your way, your people</Text>
          </Grid>
          <Grid item xs={12}>
            <Checklist/>
          </Grid>
          {
            (user && !user.isSubscribed) ?
              <>
                <Grid item xs={12}>
                  <Product
                    onSelectItem={() => setItemSelected(1)}
                    title={'$4.99 MONTHLY'} id={1} subtitle={"First 30 days free"}
                    selected={itemSelected ? itemSelected === 1 : false}/>
                </Grid>
                <Grid item xs={12}>
                  <Product
                    onSelectItem={() => setItemSelected(2)}
                    title={'$36 YEARLY ($3/month)'} id={2} subtitle={"First 30 days free"}
                    selected={itemSelected ? itemSelected === 2 : false}/>
                </Grid>
                <Grid item xs={12} style={{textAlign: 'center'}}>
                  <SubscriptionTerms>
                    Subscription terms: After free trial, annual subscription is $36 and automatically <br/>
                    renews each year and monthly subscription is $4.99 and automatically renews each month. <br/>
                  </SubscriptionTerms>
                </Grid>
                <Grid item xs={12} style={{textAlign: 'center'}}>
                  <Button disabled={!itemSelected} onClick={() => _goToCheckout()}>TRY FREE & SUBSCRIBE</Button>
                </Grid>
              </>
              :
              <>
                <Grid item xs={12} style={{textAlign: 'center'}}>
                  <p style={{fontSize:20}}>
                    You are already a subscriber. Thank you for supporting this project!
                  </p>
                </Grid>
                <Grid item xs={12} style={{textAlign: 'center'}}>
                  <Button onClick={() => {
                    dispatch({type: AUTH_ACTIONS.LOGOUT});
                    history.push(`/${ROOT_ROUTES.AUTH}/${AUTH_ROUTES.LOGIN}`);
                  }}>LOGOUT</Button>
                </Grid>
              </>
          }

          {/*{
            newUser &&
            <Grid item xs={12}>
              <Product
                onSelectItem={() => setItemSelected(3)}
                title={'PRIXM LIMITED'} id={3} subtitle={"Try PRIXM for free with limited features!"}
                selected={itemSelected ? itemSelected === 3 : false}/>
            </Grid>
          }*/}

        </Grid>

      </Container>
    </div>
  )
};

export default Subscribe;