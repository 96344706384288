import React from 'react';
import Item from "./Item";
import styled from "styled-components";

const TEXT_ITEMS = [
  "Safe online space",
  "Intuitive Storyline",
  "Create compilation videos",
  "Choose level of privacy",
  "Own what you share"
];


const Container = styled.ul`
  max-width: 60%;
  margin: 0 auto;
  list-style: none;
`

const Checklist = () => {


  return (
    <Container>
      {
        TEXT_ITEMS.map((item, index) => <Item key={index} text={item}/>)
      }
    </Container>
  )
};

export default Checklist;