import styled from "styled-components";
import {useTheme} from "@material-ui/core";
const Text = styled.p`
  color: #FFFFFF;
  font-size: 26px;
  margin: 0;
  text-align: center;
  font-weight: lighter;
  padding: 4px;
`


const Header = () => {
  const theme = useTheme();
  return (
    <Text style={{backgroundColor: theme.palette.primary.main}}>P R I X M</Text>
  )
};

export default Header;