import {gql} from '@apollo/client';
import StripePriceFragment from "./StripePrice.fragment";

const selection = `
          id
          object
          active
          created
          description
          name
          images`;
const StripeProductFragment = {
  fragment: gql`
      fragment StripeProduct on StripeProduct{
          ${selection}
          price { ...StripePrice }
      }
      ${StripePriceFragment.fragment}
  `
}
export default StripeProductFragment;