import {gql} from "@apollo/client";
import StripeProductFragment from "../fragments/StripeProduct.fragment";

export const GET_PRODUCT = gql`
    query getStripeProduct($input: getStripeProductInput) {
        getStripeProduct(input: $input) {
            ...StripeProduct
        }
    }
    ${StripeProductFragment.fragment}
`;

export default GET_PRODUCT;