import React, {useContext, useState} from 'react';
import {AuthContainer} from "../../../../components/AuthContainer";
import {CircularProgress, Grid, useTheme} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {AUTH_ROUTES, ROOT_ROUTES, SUBSCRIPTION_ROUTES} from "../../../../../../shared/constants/routes";
import Input from "../../../../../../shared/components/Input";
import styled from "styled-components";
import {Underline} from "../../../../components/Form/Underline";
import Button from "../../../../../../shared/components/Button";
import {useFormik} from "formik";
import * as yup from 'yup';
import RestApi from "../../../../../../config/rest-api.config";
import {AuthContext} from "../../../../../../shared/context/AuthContext";
import {AUTH_ACTIONS} from "../../../../../../shared/context/AuthContext/Constants";

const validationSchema = yup.object().shape({
  email: yup.string().email().required("Required field"),
  password: yup.string().required("Required field"),
})

const Footer = styled.p`
  font-weight: bold;
  font-size: 14px;
`;

const Title = styled.p`
  font-weight: bold;
  font-size: 24px;
  margin: 0;
`;

const GRID_STYLES = {width: "100%", margin: "0 auto"};

const MessageError = styled.p`
  font-size: 18px;
  color: #F44336;
  margin: 0 auto;
`

interface IForm {
  password: string;
  email: string;
}


const BottomForm = () => {
  const theme = useTheme();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const {dispatch: authDispatch} = useContext(AuthContext);

  const _goToRegister = () => {
    history.push(`/${AUTH_ROUTES.BASE}/${AUTH_ROUTES.REGISTER}`);
  }

  const onSubmit = (values: IForm) => {
    setError(null);
    setLoading(true)
    RestApi.post('login', values)
      .then((res) => {
        if (res.data && res.data.token) {
          const token = res.data.token;
          const user = res.data;
          authDispatch({type: AUTH_ACTIONS.LOGIN, user: user, token: token});
          history.push(`/${ROOT_ROUTES.SUBSCRIPTION}/${SUBSCRIPTION_ROUTES.SUBSCRIBE}`);
        }
        //TODO:REDIRECT TO HOME OR CHECKOUT
      })
      .catch((err) => {
        if (err.response) {
          setError(err.response.data.message || 'Wrong credentials')
        } else {
          setError('Wrong credentials')
        }
      })
      .finally(() => setLoading(false));
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    onSubmit,
    validationSchema,
  })


  return (
    <AuthContainer style={{backgroundColor: theme.palette.primary.main}}>
      <Title>PRIXM</Title>
      {
        error &&
        <MessageError>{error}</MessageError>
      }
      {
        loading &&
        <CircularProgress color={"secondary"}/>
      }
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} justify="center" alignItems="stretch" direction={'column'}>
          <Grid item xs={12} md={4} style={GRID_STYLES}>
            <Input
              style={{width: '97%'}}
              name={'email'}
              type={'email'}
              onChange={formik.handleChange}
              value={formik.values.email}
              placeholder={"EMAIL"}/>
          </Grid>
          <Grid item xs={12} md={4} style={GRID_STYLES}>
            <Input
              style={{width: '97%'}}
              name={'password'}
              type={'password'}
              onChange={formik.handleChange}
              value={formik.values.password}
              placeholder={"PASSWORD"}/>
          </Grid>
          <Grid item xs={12} md={4} style={GRID_STYLES}>
            <Button type={"submit"} disabled={!formik.isValid || loading} style={{maxWidth: '100%'}}>LOGIN</Button>
          </Grid>
        </Grid>

        <Footer>Don't have an account? <Underline onClick={_goToRegister}>REGISTER</Underline></Footer>
      </form>
    </AuthContainer>
  )
};

export default BottomForm