import styled from "styled-components";
import {useTheme} from "@material-ui/core";
import {IStripeProduct} from "../../../../../shared/interfaces/StripeProduct";

interface IProps {
  product: IStripeProduct

}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Title = styled.p`
  font-weight: bolder;
  font-size: 20px;
  margin: 0
`;

const Subtitle = styled.p`
  margin: 0;
`;

const Price = styled.p`
  font-weight: bolder;
  font-size: 20px;
  margin: 0;
  color: ${(props: any) => props.color || "#FFFFFF"}
`

const Product = (props: IProps) => {
  const {product} = props;
  const theme = useTheme();

  const formatString = (value: string) => {
    return `$${value.slice(0, value.length - 2)}.${value.slice(value.length - 2)}`;
  }

  const _getInterval = () => {
    if(product.price.recurring.interval === 'month'){
      return 'mo';
    }
    return 'year'
  }

  return (
    <Container>
      <div>
        <Title>{product.name}</Title>
      </div>
      <Price color={theme.palette.primary.main}>{formatString(product.price.unit_amount_decimal)}/{_getInterval()}</Price>
    </Container>
  )
};

export default Product;