import {createMuiTheme} from "@material-ui/core";

const Theme = createMuiTheme({
  typography: {
    // fontFamily: 'ProximaNovaA-Regular',
    /*display3: {
      color: '#FFFFFF',
    },
    display2: {
      color: '#FFF',
      fontSize: 40,
      marginBottom: 40,
      fontWeight: 300,
    },
    display4: {
      color: '#FFF',
      fontSize: 33,
      marginBottom: 10,
      fontWeight: 300,
    },*/
    button: {
    }
  },

  palette: {
    primary: {
      main: '#f9cf2d',
      light: '#fbd647',
      dark: '#1a1a1a',
      contrastText: '#fff',
    },
    secondary: {
      main: '#FFF',
    },
  },
});

export default Theme;