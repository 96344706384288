import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import BackgroundImage from '../../assets/images/login_bakground_light.jpg';
import styled from "styled-components";
import {CircularProgress, createStyles, Grid, makeStyles, Theme} from "@material-ui/core";
import {useFormik} from "formik";
import Input from "../../shared/components/Input";
import Button from "../../shared/components/Button";
import * as yup from "yup";
import RestApi from "../../config/rest-api.config";

const validationSchema = yup.object().shape({
  token: yup.string().required("Required field"),
  newPassword: yup.string().required("Required field"),
  confirmPassword: yup.string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match').required("Required")
})

const MessageError = styled.p`
  color: #F44336;
  font-size: 16px;
`;

const Background = styled.div`
  background-image: url(${BackgroundImage});
  width: 100%;
  height: 100%;
  backgroundSize: cover;
  backgroundPosition: center;
  position: fixed;
`;

const FormContainer = styled.div`
  width: 350px;
  height: 100px;
  float: right;
  text-align: center;
`

const Title = styled.h1`
  color: #FFFFFF;
`;

const Subtitle = styled.p`
  color: rgba(255, 255, 255, .80);
  font-size: 15px;
  margin-top: 0;
  margin-bottom: 30px;
  font-weight: 300;
`

const useStyles = makeStyles((theme: Theme) => (
  createStyles({
    forgotBox: {
      [theme.breakpoints.down('xs')]: {
        width: '90%',
        padding: '15% 5%',
      },
      [theme.breakpoints.up('sm')]: {
        margin: '100px 50px 0 0',
      },
      [theme.breakpoints.up('md')]: {
        margin: '100px 100px 0 0',
      },
      [theme.breakpoints.up('lg')]: {
        margin: '100px 150px 0 0',
      },
    },
  })
))

interface IForm {
  token: string;
  newPassword: string;
  confirmPassword: string;
}

const ChangePassword = () => {
  const classes = useStyles();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const onSubmit = (values: IForm) => {
    setLoading(true);

    RestApi.post('change', values)
      .then(res => {
        setSuccess(true);
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.message) {
          setError(err.response.data.message);
        } else {
          setError('Error changing password. Please try again');
        }

      })
      .finally(() => setLoading(false));

  }

  const formik = useFormik({
    initialValues: {
      token: "",
      newPassword: "",
      confirmPassword: ""
    },
    onSubmit,
    validationSchema
  });

  useEffect(() => {
    // @ts-ignore
    if (params && params.token) {
      // @ts-ignore
      formik.setFieldValue('token', params.token);
    }
  }, [params]);

  return (
    <Background>
      <FormContainer className={classes.forgotBox}>

        {
          success ?
            <>
              <Subtitle>
                Hooray! You’ve successfully changed your password!<br/>
                Head back over to Prixm to log in!"
              </Subtitle>
            </>
            :
            <>
              <Title>CHANGE PASSWORD</Title>
              <Subtitle>Please enter your new password</Subtitle>
              {
                loading &&
                <CircularProgress color={"secondary"}/>
              }
              {
                error &&
                <MessageError>{error}</MessageError>
              }
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Input
                      placeholder={"New Password"}
                      disabled={loading}
                      type={"password"}
                      name={"newPassword"}
                      value={formik.values.newPassword}
                      onChange={formik.handleChange}/>
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      placeholder={"Confirm new password"}
                      disabled={loading}
                      type={"password"}
                      name={"confirmPassword"}
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}/>
                  </Grid>
                  <Grid item xs={12}>
                    <Button disabled={!formik.isValid} type={'submit'}>SUBMIT</Button>
                  </Grid>
                </Grid>
              </form>
            </>
        }

      </FormContainer>
    </Background>
  )
}

export default ChangePassword;