import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import BackgroundImage from '../../assets/images/login_bakground_light.jpg';
import styled from "styled-components";
import {CircularProgress, createStyles, Grid, makeStyles, Theme} from "@material-ui/core";
import * as yup from "yup";
import RestApi from "../../config/rest-api.config";

const MessageError = styled.p`
  color: #F44336;
  font-size: 16px;
`;

const Background = styled.div`
  background-image: url(${BackgroundImage});
  width: 100%;
  height: 100%;
  backgroundSize: cover;
  backgroundPosition: center;
  position: fixed;
`;

const FormContainer = styled.div`
  width: 350px;
  height: 100px;
  float: right;
  text-align: center;
`

const Title = styled.h1`
  color: #FFFFFF;
`;

const Subtitle = styled.p`
  color: rgba(255, 255, 255, .80);
  font-size: 15px;
  margin-top: 0;
  margin-bottom: 30px;
  font-weight: 300;
`

const useStyles = makeStyles((theme: Theme) => (
  createStyles({
    forgotBox: {
      [theme.breakpoints.down('xs')]: {
        width: '90%',
        padding: '15% 5%',
      },
      [theme.breakpoints.up('sm')]: {
        margin: '100px 50px 0 0',
      },
      [theme.breakpoints.up('md')]: {
        margin: '100px 100px 0 0',
      },
      [theme.breakpoints.up('lg')]: {
        margin: '100px 150px 0 0',
      },
    },
  })
))

const VerifyUser = () => {
  const classes = useStyles();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const onSubmit = (token: string) => {
    setLoading(true);
    RestApi.post('email-verify', {token})
      .then(res => {
        setSuccess(true);
      })
      .catch(err => {
        console.log(err.response.data)
        if (err.response && err.response.data && err.response.data.message && err.response.data.message.length > 0) {
          setError(err.response.data.message);
        } else {
          setError('Error verifying your account. Please try again');
        }

      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    // @ts-ignore
    if (params && params.token) {
      // @ts-ignore
      onSubmit(params.token);
    }
  }, [params]);

  return (
    <Background>
      <FormContainer className={classes.forgotBox}>
        {
          success ?
            <>
              <Subtitle>
                Thank you for verifying your email, your account is now activated. <br/>
                May we be the first to welcome to the Prixm community! Start telling your story and connecting with
                those you care about by heading back to the app.<br/>
                If you have any issues or questions, please send us an email at support@prixm.com and we’d be happy to
                assist you. <br/>
              </Subtitle>
            </>
            :
            <>
              <Title>WELCOME</Title>
              <Subtitle>Please wait while we verify your account</Subtitle>
              {
                loading &&
                <CircularProgress color={"secondary"}/>
              }
              {
                error &&
                <MessageError>{error}</MessageError>
              }
            </>
        }

      </FormContainer>
    </Background>
  )
}

export default VerifyUser;