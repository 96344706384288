import {gql} from "@apollo/client";

export const CREATE_STRIPE_SUBSCRIPTION = gql`
    mutation createSubscription($input: createSubscriptionInput) {
        createSubscription(input: $input) {
            _id
        }
    }
`;

export default CREATE_STRIPE_SUBSCRIPTION;
