import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import Input from "./Input";
import DISCOUNT_CODE_IS_ELIGIBLE from "../../../../../graphql/discountCode/isEligible.query";
import {useLazyQuery} from "@apollo/client";
import {Button as MuiButton, LinearProgress, Theme, withStyles} from "@material-ui/core";
import {IDiscountCode} from "../../../../../shared/interfaces/Discount";
import {get} from 'lodash';
import {IStripeProduct} from "../../../../../shared/interfaces/StripeProduct";
import {getApolloError} from "../../../../../shared/utils/apolloError";

const Title = styled.p`
  font-weight: bold;
  margin: 0 0 4px 24px;
`;

const Spacer = styled.span`
  width: 10%;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const MessageError = styled.p`
  color: #F44336;
  font-size: 16px;
`

const Button = withStyles((theme: Theme) => ({
  root: {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    fontWeight: 'bold',
    color: "white",
    '&:disabled': {
      backgroundColor: theme.palette.grey["300"],
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}))(MuiButton);

interface IProps {
  onDiscountCode: (discountCode: IDiscountCode) => void;
  product: IStripeProduct | null;
}

const DiscountCodeSection = (props: IProps) => {
    const {onDiscountCode, product} = props
    const [value, setValue] = useState<string>("");
    const [showError, setShowError] = useState<boolean>(false);
    const [isEligible, {data, loading, error}] = useLazyQuery(DISCOUNT_CODE_IS_ELIGIBLE);

    const _isEligible = () => {
      if (value) {
        isEligible({
          variables: {
            input: {
              code: value
            }
          }
        })
      }
    }

    useEffect(() => {
      if (data) {
        console.log(data)
        const discountCode: IDiscountCode = get(data, 'DiscountCodeIsEligible', null);
        if (product && discountCode.applies_to && discountCode.applies_to.products && discountCode.applies_to.products.length > 0) {
          if (discountCode.applies_to.products.find(value => value.trim() === product.id)) {
            setShowError(false);
            onDiscountCode(discountCode);
          } else {
            setShowError(true);
          }
        } else {
          setShowError(false);
          onDiscountCode(discountCode);
        }
      }
    }, [data]);

    useEffect(() => {
      if (error) {
        const m = getApolloError(error);
        setShowError(m || 'Error applying the coupon');
      }
    }, [error])

    return (
      <div>
        {
          loading &&
          <LinearProgress/>
        }

        <Title>DISCOUNTS</Title>
        <Row>
          <Input
            value={value}
            onChange={(event) => setValue(event.target.value)}
            placeholder={"Add promo code"}/>
          <Spacer/>
          <Button disabled={!value} onClick={() => _isEligible()}>APPLY</Button>
        </Row>
        {
          showError &&
          <MessageError>{showError}</MessageError>
        }
      </div>
    )
  }
;

export default DiscountCodeSection;