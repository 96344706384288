import React from 'react';
import styled from "styled-components";
import PhoneImage from '../../../../assets/images/phone.png';

const Image = styled.img`
  max-width: 15%;
  max-height: 50%;
  margin:0 auto;
`;

const Container = styled.div`
  text-align: center;
`;

const Title = styled.p`
  font-weight: bold;
  margin: 0 auto  ;
  font-size: 24px;
`;

const Subtitle = styled.p`
  font-weight: bold;
  margin: 8px auto;
  line-height: 16px;
  font-size: 16px;
`

interface IProps {
  title: string;
  subtitle: string
}

const PhoneWithText = (props: IProps) => {

  const{subtitle, title} = props;

  return (
    <Container>
      <Image src={PhoneImage}/>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </Container>
  )
};

export default PhoneWithText;