import React from 'react';
import {ApolloProvider} from "@apollo/client";
import apolloClient from "./config/graphql.config";
import {AuthProvider} from "./shared/context/AuthContext";
import Root from "./containers/Root";
import {MuiThemeProvider} from "@material-ui/core";
import Theme from "./config/theme.config";

function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <MuiThemeProvider theme={Theme}>
        <AuthProvider>
          <Root/>
        </AuthProvider>
      </MuiThemeProvider>
    </ApolloProvider>
  );
}

export default App;
