import {gql} from '@apollo/client';
import StripePriceRecurringFragment from "./StripeRecurringPrice.fragment";

const selection = `
  id
  active
  created
  currency
  type
  unit_amount
  unit_amount_decimal`;

const StripePriceFragment = {
  fragment: gql`
      fragment StripePrice on StripePrice{
          ${selection}
          recurring {...StripePriceRecurring}
      }
      ${StripePriceRecurringFragment.fragment}
  `
}
export default StripePriceFragment;