import React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import PublicRoute from "../shared/components/PublicRoute";
import AuthRouter from "./Auth";
import {AUTH_ROUTES, ROOT_ROUTES} from "../shared/constants/routes";
import NoMatch from "./NoMatch";
import PrivateRoute from "../shared/components/PrivateRoute";
import SubscriptionRouter from "./Subscription";
import VerifyUser from "./VerifyUser";
import ChangePassword from "./ChangePassword";


const Root = () => {
  return (
    <Router>
      <Switch>
        <PrivateRoute path={`/${ROOT_ROUTES.SUBSCRIPTION}`}>
          <SubscriptionRouter/>
        </PrivateRoute>
        <PublicRoute path={`/${ROOT_ROUTES.AUTH}`} component={AuthRouter}/>
        <PublicRoute path={`/${ROOT_ROUTES.AUTH}`} component={AuthRouter}/>
        <PublicRoute path={`/${ROOT_ROUTES.VERIFY}/:token`} component={VerifyUser}/>
        <PublicRoute path={`/${ROOT_ROUTES.CHANGE}/:token`} component={ChangePassword}/>
        <Route exact path="/">
          <Redirect to={{pathname: `/${ROOT_ROUTES.AUTH}/${AUTH_ROUTES.LOGIN}`}}/>
        </Route>
        <Route exact path="">
          <Redirect to={{pathname: `/${ROOT_ROUTES.AUTH}/${AUTH_ROUTES.LOGIN}`}}/>
        </Route>
        <Route path="*">
          <NoMatch/>
        </Route>
      </Switch>
    </Router>
  )
};

export default Root